.page {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button {
  position: relative;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: lavender;
  user-select: none;
  transition: 0.3s;
}

.button:hover {
  padding: 0.5rem 1.5rem;
  margin: 0 0.5rem;
}

input, select {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;
  font-size: 1rem;
  line-height: 1.15rem;
  background-color: white;
}

input:focus, select:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

input {
  padding: 0 0.3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem;
  text-align: center;

  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(118, 118, 118, 0.4);
}
