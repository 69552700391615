.statistics {

  &_url {
    font-size: 1.5rem;
  }

  &_nav {
    display: flex;
    align-items: center;

    &_active {
      background-color: lightgreen !important;
    }
  }
}
