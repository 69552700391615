.info_box {
  position: relative;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;

  &_title {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    background-color: white;
  }

  &_body {
    display: flex;
    flex-direction: column;

    &_key {
      color: cornflowerblue;
    }

    &_row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 0.2rem;
      padding: 0.3rem;

      &:first-child {
        margin-top: 0;
      }

      & > span {
        margin: 0 1rem;
      }
    }

    &_row_light_up {
      background-color: whitesmoke;
    }
  }
}
