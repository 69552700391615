.sessions {
  height: 100%;
  max-height: 90vh;
  display: grid;
  grid-template-columns: 30% 70%;

  &_list {
    position: relative;
    width: 100%;
    padding: 0 1rem 0 0;
    overflow-y: auto;

    & > div {
      min-height: 100%;
      overflow-x: auto;

      & > span {
        display: inline-block;
        padding: 0.5rem 0;
        cursor: pointer;
      }
    }

    &:before {
      content: '';
      width: 1px;
      height: 100%;
      border-radius: 0.5rem;
      position: absolute;
      right: 0;
      background-color: rgba(180, 180, 180, 0.5);
    }
  }

  &_session_details {
    overflow: auto;
  }
}
