.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  opacity: 0;
  transition: 0.3s;

  .modal_container {
    position: relative;
    max-height: 40rem;
    overflow: auto;
    padding: 2rem 0 2rem 2rem;
    border-radius: 10px;
    background-color: white;
    transform: translateY(-2rem);
    transition: 0.3s;

    .modal_x_button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .modal {
      width: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-right: 2rem;
      overflow-x: hidden;
      overflow-y: auto;

      .modal_header {
        font-size: 2rem;
        text-align: center;
      }

      .modal_body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        &_message {
          height: 100%;
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .form_block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1rem;

          &:first-child {
            margin-top: 0;
          }

          label {
            width: 60%;
            color: rgba(47, 79, 79, 0.5);
            text-align: left;
          }

          input, select {
            height: 2rem;
            width: 60%;
          }

          .currency_label {
            position: absolute;
            left: 81%;
            bottom: 0;
            font-size: 1.8rem;
          }

          &_error {
            position: absolute;
            top: 102%;
            left: 20%;
            color: rgba(255, 0, 0, 0.7);
          }
        }

        .form_block_checkbox {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 0.8rem;

          label {
            width: max-content;
            margin-right: 1rem;
          }

          input {
            width: max-content;
          }
        }
      }

      .modal_controls {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 2rem;
      }
    }

    .modal_transaction {
      display: grid;
      grid-template-columns: 1fr;

      .game_result_box, .game_result_placeholder {
        height: 20rem;
        margin-bottom: 1rem;

        .book_of_bamboo,
        .book_of_eye {
          width: 75%;
          max-width: unset;
          margin: 0 auto;

          &_symbols {
            padding: 0.6rem;

            img {
              width: 100%;
              height: 100%;
              max-height: 5rem;
              max-width: 6.3rem;
            }
          }

          .book_of_eye_symbols img {
            transform: scale(1.7);
          }
        }

        .hot_and_spicy {
          width: 65%;
          max-width: unset;
          margin: 0 auto;

          &_jackpot_label {
            font-size: 1.7rem;
          }

          &_symbols {
            padding: 3rem 2.2rem 1.2rem;

            img {
              width: 100%;
              height: 100%;
              max-height: 5rem;
              max-width: 6.3rem;
            }
          }

          &_peppers {
            height: 3.2rem;

            img {
              width: 2rem;
              margin: 0 0.5rem;
            }
          }
        }

        .coin svg {
          height: 100%;
        }

        .golden_clover {

          img {
            height: 100%;
            width: 100%;
            max-width: 6.5rem;
          }
        }

        .lucky_clover {
          margin: 0 auto;

          svg {
            height: 100%;
            width: 100%;
          }
        }

        .lucky_ocean {
          &_bubbles {
            img, svg {
              max-width: 2.8rem;
              max-height: 2.8rem;
            }
          }
        }

        .troll_dice {
          &_dices {
            img {
              width: 5rem;
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
          }
        }

        .limbo_cat, .fighter {
          &_coef {
            font-size: 4.5rem;
          }
        }

        .lucky_tanks {
          &_background {
            width: 75%;
          }

          &_field {
            width: 40%;

            img, .lucky_tanks_placeholder {
              max-width: 100%;
              margin: -0.5rem;
            }
          }
        }

        .thimbles {
          &_field {

            img {
              max-width: 6rem;
            }
          }
        }

      }
    }

    .info_modal_item {
      width: 100%;
      word-break: break-word;
      margin: 1rem auto;

      & > div:first-child {
        text-align: center;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
    }

    .modal_s {
      height: 20rem;
    }

    .modal_m {
      height: 25rem;
    }

    .modal_l {
      width: 60rem;
      height: 35rem;
    }
  }
}

.users_manager {
  &_header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;

    & > span {
      padding: 0.2rem 0.3rem;
      font-size: 1.2rem;
      transition: 0.4s;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: lavender;
      }
    }

    .active {
      background-color: lavender;
    }
  }

  &_body {
    width: 200%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: 0.3s;
  }
}

.modal_wrapper_visible {
  visibility: visible;
  opacity: 1;

  .modal_container {
    transform: translateY(0);
  }
}

.modal_wrapper_error {
  z-index: 101;
}

@media only screen and (max-width: 992px) {
  .modal {
    width: 30rem !important;

    .lucky_tanks {
      &_field {
        width: 80% !important;
      }
    }
  }
}
