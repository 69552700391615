* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: Ubuntu, 'sans-serif';
}

.app {
  width: 100vw;
  height: 100vh;
}
